/* eslint-disable react/no-string-refs */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { userCreate, userUpdate } from "../../api/user.api";
import { getSession, saveSession, deleteSession } from "../../utils/session";

import { managerRoles } from "../../helpers/constants";

import useStyles from "./styles";

const UserPopup = ({
  isPopupOpen, editingUser, handleTogglePopup, updateUsers,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setUser(editingUser), [editingUser]);

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== user.password) {
        return false;
      }
      return true;
    });
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [user.password]);

  const handleChange = (event) => {
    const updatedUser = user
      ? { ...user, [event.target.name]: event.target.value }
      : { [event.target.name]: event.target.value };
    setUser(updatedUser);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!user.id) {
        const newUser = await userCreate(user);
        updateUsers(newUser);
      } else {
        const userObj = {
          id: user.id,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
          status: user.status,
        };
        if (user.isUpdatePassword) {
          userObj.password = user.password;
        }
        const updatedUser = await userUpdate(userObj);
        const { user: currentUser, userToken } = getSession();
        if (currentUser.id === updatedUser.id) {
          saveSession({
            ...currentUser,
            username: updatedUser.username,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            role: updatedUser.role,
          }, userToken);
          if (user.status !== "active") {
            deleteSession();
            history.push("/login");
          }
          if (currentUser.role !== updatedUser.role) {
            history.push("/");
          }
        }
        updateUsers(updatedUser);
      }
      handleTogglePopup(handleTogglePopup);
      setError("");
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={isPopupOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") handleTogglePopup();
      }}
      disableEscapeKeyDown
    >
      <Box className={classes.popup}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Typography variant="h6" component="h6" align="center">
            {!editingUser?.id ? t("users.createUserAccount") : t("users.updateUserAccount")}
          </Typography>
          <TextValidator
            name="username"
            label={t("form.username")}
            className={classes.formField}
            value={user.username || ""}
            onChange={handleChange}
            fullWidth
            validators={user.id ? [] : ["required", "minStringLength:2", "maxStringLength:30"]}
            errorMessages={user.id ? [] : [
              t("validation.requiredField"),
              t("validation.lengthFrom2To30"),
              t("validation.lengthFrom2To30"),
            ]}
          />
          <TextValidator
            name="firstName"
            label={t("form.firstName")}
            className={classes.formField}
            value={user.firstName || ""}
            onChange={handleChange}
            fullWidth
          />
          <TextValidator
            name="lastName"
            label={t("form.lastName")}
            className={classes.formField}
            value={user.lastName || ""}
            onChange={handleChange}
            fullWidth
          />
          <TextValidator
            label={t("form.email")}
            onChange={handleChange}
            name="email"
            value={user.email || ""}
            fullWidth
            validators={["isEmail"]}
            errorMessages={[t("validation.requiredField")]}
          />
          <SelectValidator
            name="role"
            label={t("form.role")}
            helperText=""
            className={classes.formField}
            value={user?.role || ""}
            validators={["required"]}
            fullWidth
            errorMessages={[t("validation.requiredField")]}
            onChange={handleChange}
          >
            {Object.keys(managerRoles).map((item) => (
              <option key={item} value={item} className={classes.optionField}>
                {t(managerRoles[item])}
              </option>
            ))}
          </SelectValidator>
          {user.id ? (
            <>
              <br />
              <FormControlLabel
                control={<Checkbox checked={user.isUpdatePassword} onChange={handleChange} name="isUpdatePassword" value={user.isUpdatePassword ? "" : "true"} />}
                label={t("form.updatePassword")}
              />
            </>
          ) : null}
          <TextValidator
            name="password"
            label={t("form.password")}
            type="password"
            helperText={t("users.passwordHelperText")}
            className={classes.formField}
            value={user?.password || ""}
            onChange={handleChange}
            fullWidth
            disabled={user.id && !user.isUpdatePassword}
            validators={user.id && !user.isUpdatePassword ? [] : ["required", "minStringLength:2"]}
            errorMessages={user.id && !user.isUpdatePassword ? [] : [
              t("validation.requiredField"),
              t("validation.lengthLongerThan2"),
              t("validation.lengthShorterThan20"),
            ]}
          />
          <TextValidator
            name="passwordConfirmation"
            label={t("form.confirmPassword")}
            type="password"
            className={classes.formField}
            value={user?.passwordConfirmation || ""}
            onChange={handleChange}
            fullWidth
            disabled={user.id && !user.isUpdatePassword}
            validators={user.id && !user.isUpdatePassword ? [] : ["isPasswordMatch", "required"]}
            errorMessages={user.id && !user.isUpdatePassword ? [] : [t("validation.passwordMismatch"), t("validation.requiredField")]}
          />
          {error ? (
            <Typography variant="body2" color="error" gutterBottom>
              {error}
            </Typography>
          ) : null}
          <Box style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Button size="small" variant="contained" color="primary" type="submit" disabled={isLoading}>
              {!editingUser?.id ? t("form.create") : t("form.update")}
            </Button>
            <Button size="small" variant="contained" color="primary" disabled={isLoading} onClick={handleTogglePopup} style={{ marginLeft: "10px" }}>
              {t("common.close")}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </Modal>
  );
};

UserPopup.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleTogglePopup: PropTypes.func.isRequired,
  editingUser: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  updateUsers: PropTypes.func.isRequired,
};

export default UserPopup;
