import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import UserPopup from "./userPopup";
import ConfirmationPopup from "./confirmationPopup";

import { userGetList, userDelete } from "../../api/user.api";
import { getSession, deleteSession } from "../../utils/session";

import { roles } from "../../helpers/constants";

import useStyles from "./styles";

const Users = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({});
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [error, setError] = useState(null);

  const columns = [
    {
      field: "username",
      headerName: t("form.username"),
      flex: 1,
      minWidth: 80,
    },
    {
      field: "firstName",
      headerName: t("form.firstName"),
      width: 350,
    },
    {
      field: "lastName",
      headerName: t("form.lastName"),
      width: 200,
    },
    {
      field: "email",
      headerName: t("form.email"),
    },
    {
      field: "role",
      headerName: t("form.role"),
      width: 110,
      // eslint-disable-next-line react/prop-types
      renderCell: ({ value }) => <Typography variant="subtitle2">{t(roles[value])}</Typography>,
    },
    {
      field: "edit",
      headerName: t("form.edit"),
      sortable: false,
      width: 50,
      disableClickEventBubbling: true,
      isEditable: false,
      disableExport: true,
      filterable: false,
      renderCell: () => <IconButton size="small"><EditIcon className="edit" color="primary" /></IconButton>,
      renderHeader: () => <div />,
    },
    {
      field: "delete",
      headerName: t("form.delete"),
      sortable: false,
      width: 50,
      disableClickEventBubbling: true,
      isEditable: false,
      disableExport: true,
      filterable: false,
      renderCell: () => <IconButton size="small"><DeleteIcon className="delete" color="primary" /></IconButton>,
      renderHeader: () => <div />,
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setError(null);
      try {
        const data = await userGetList();
        setUsers(data);
      } catch (e) {
        setError(e.message);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const currentlySelected = (params) => {
    const { row, field } = params;
    if (field === "delete") {
      setDeleteUserId(row.id);
      setIsConfirmationOpen(true);
      return;
    }
    if (field === "edit") {
      setEditingUser(row);
      setIsPopupOpen(true);
    }
  };

  const handleTogglePopup = useCallback(
    () => setIsPopupOpen(!isPopupOpen),
    [setIsPopupOpen, isPopupOpen],
  );

  const handleAddNewUser = useCallback(
    () => {
      setEditingUser({});
      handleTogglePopup();
    },
    [setEditingUser, handleTogglePopup],
  );

  const updateUsers = useCallback(
    (user) => {
      const filteredUsers = users.filter(({ id }) => id !== user.id);
      setUsers([...filteredUsers, user]);
    },
    [setUsers, users],
  );

  const handleConfirmationPopup = useCallback(
    () => setIsConfirmationOpen(!isConfirmationOpen),
    [setIsConfirmationOpen, isConfirmationOpen],
  );

  const handleRemoveUser = useCallback(
    async () => {
      try {
        if (!deleteUserId) return;
        await userDelete(deleteUserId);
        const { user } = getSession();
        if (user.id === deleteUserId) {
          deleteSession();
          history.push("/login");
        }
        setUsers(users.filter(({ id }) => id !== deleteUserId));
        setIsConfirmationOpen(false);
      } catch (e) {
        console.log(e);
      }
    },
    [deleteUserId, users, history],
  );

  useEffect(() => {
    if (!isConfirmationOpen) setDeleteUserId(null);
  }, [isConfirmationOpen]);

  const QuickSearchToolbar = () => (
    <Box sx={{ p: 1 }}>
      <GridToolbarQuickFilter />
    </Box>
  );

  return (
    <div style={{
      height: 700,
      minHeight: 300,
      maxWidth: 930,
    }}
    >
      <Button size="small" variant="contained" color="primary" startIcon={<AddIcon />} style={{ marginBottom: "10px" }} onClick={handleAddNewUser}>
        {t("form.create")}
      </Button>
      <DataGrid
        className={classes.root}
        rows={users || []}
        columns={columns}
        pagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        pageSize={rowsPerPage}
        onPageSizeChange={(page) => setRowsPerPage(page)}
        rowHeight={40}
        autoHeight
        disableColumnMenu
        onCellClick={currentlySelected}
        isRowSelectable={() => false}
        components={{
          Toolbar: QuickSearchToolbar,
          LoadingOverlay: () => <LinearProgress style={{ display: "block", margin: "0 auto" }} />,
          ErrorOverlay: () => <Box component="div" className={classes.gridMessage}><Typography variant="subtitle2" color="error">{error}</Typography></Box>,
          NoResultsOverlay: () => (
            <Box height="100%" alignItems="center" justifyContent="center" display="flex">
              {t("form.noResults")}
            </Box>
          ),
        }}
        error={error}
        loading={isLoading}
        initialState={{
          sorting: { sortModel: [{ field: "username", sort: "asc" }] },
          columns: {
            columnVisibilityModel: { email: false },
          },
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("form.labelRowsPerPage"),
          },
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: t("form.searchPlaceholder"),
          columnHeaderSortIconLabel: t("form.sortLabel"),
        }}
      />
      <UserPopup
        isPopupOpen={isPopupOpen}
        handleTogglePopup={handleTogglePopup}
        editingUser={editingUser}
        updateUsers={updateUsers}
      />
      <ConfirmationPopup
        isPopupOpen={isConfirmationOpen}
        handleTogglePopup={handleConfirmationPopup}
        handleActionClick={handleRemoveUser}
      />
    </div>
  );
};

export default Users;
