/* eslint-disable indent */
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { getSession } from "../../utils/session";
import { permissions } from "../../helpers/permissions";

const PublicRoute = ({
  component: Component, layout: Layout, title, ...rest
}) => {
  const { location } = rest;
  const session = getSession();
  const authRoutes = ["/login"];

  if (session && authRoutes.includes(location.pathname)) {
    const { role } = session.user;
    const defaultRoute = permissions[role].default;
    return <Redirect to={defaultRoute} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <Layout>
          <Component {...routeProps} title={title} />
        </Layout>
      )}
    />
  );
};

PublicRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  title: PropTypes.string,
};

PublicRoute.defaultProps = {
  title: "",
};

export default PublicRoute;
