import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { getSession } from "../../utils/session";

import { permissions, menu } from "../../helpers/permissions";

import PrivateRoute from "../PrivateRoute";

const RoleBasedRouting = ({
  component: Component, path, ...rest
}) => {
  const { user } = getSession() || {};
  if (!user) {
    return (
      <Redirect to="/login" />
    );
  }
  const { role } = user;

  const allowedRoutes = permissions[role].routes;
  const defaultRoute = permissions[role].default;

  // eslint-disable-next-line no-unused-vars
  const [pathId] = Object.entries(menu).find(([key, value]) => value.path === path);

  console.log("path:", path);
  console.log("pathId:", pathId);
  console.log("bool:", allowedRoutes.includes(pathId));

  return (
    allowedRoutes.includes(pathId)
      ? <PrivateRoute path={path} {...rest} component={Component} />
      : <Redirect to={menu[defaultRoute].path} />
  );
};

RoleBasedRouting.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  title: PropTypes.string,
};

RoleBasedRouting.defaultProps = {
  title: "",
};

export default RoleBasedRouting;
