import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@material-ui/icons/Language";
import i18n from "../../i18n";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [lang, setLang] = React.useState(i18n.language);

  const changeLang = (lng) => {
    i18n.changeLanguage(lng, () => setLang(lng));
    setAnchorEl(null);
  };

  const handleToggleMenu = (event) => {
    if (!open) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ColorButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleToggleMenu}
        startIcon={<LanguageIcon />}
      >
        {t(`languageSwitcher.${lang}`)}
      </ColorButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => changeLang("en")}>{t("languageSwitcher.en")}</MenuItem>
        <MenuItem onClick={() => changeLang("es")}>{t("languageSwitcher.es")}</MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
