import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./translation";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    resources,
    interpolation: { escapeValue: false },
  });

export default i18n;
