import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";

import LanguageSwitcher from "../LanguageSwitcher";

import { getSession, deleteSession } from "../../utils/session";
import useStyles from "./styles";

const TopBar = ({ toggleMenu, title, drawerWidth }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleLogoutClick = () => {
    deleteSession();
    history.push("/login");
  };

  const { user } = getSession() || {};
  const { username } = user || {};

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <Button onClick={() => toggleMenu(true)}>
            <Menu className={classes.icon} />
          </Button>
        </Box>
        <Typography variant="h6" color="inherit" className={classes.title}>{title}</Typography>
        <LanguageSwitcher />
        {user && (
          <Box display="flex" alignItems="center" className={classes.user}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="false"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Typography className={classes.username}>{`${username}`}</Typography>
          </Box>
        )}
        {user && (
          <Button color="inherit" className={classes.logout} onClick={handleLogoutClick}>Logout</Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};

export default TopBar;
