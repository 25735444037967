import { requestApi } from "../utils/request";
import { getSession } from "../utils/session";

const txGetListByUsername = async (username) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUsername", "POST", { username }, {
    Authorization: `Bearer ${userToken}`,
  });
};

const txExportByUsername = async (username, from, to) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUsername/export", "POST", { username, from, to }, {
    Authorization: `Bearer ${userToken}`,
  });
};

const txDeleteByUsername = async (username) => {
  const { userToken } = getSession();
  return requestApi("/transactions/byUsername", "DELETE", { username }, {
    Authorization: `Bearer ${userToken}`,
  });
};

export {
  txGetListByUsername, txDeleteByUsername, txExportByUsername,
};
