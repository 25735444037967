import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexGrow: 1,
  },
  icon: {
    color: "#fff",
  },
  title: {
    flexGrow: 1,
  },
  username: {
    padding: theme.spacing(1),
  },
  user: {
    paddingLeft: theme.spacing(4),
  },
  logout: {
    paddingLeft: theme.spacing(4),
  },
}));

export default useStyles;
