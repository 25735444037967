import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PublicRoute from "./router/PublicRoute";
import RoleBasedRouting from "./router/RoleBasedRouting";

import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout";

import Users from "./pages/Users";
import Login from "./pages/Login";
import Transactions from "./components/Transactions";
import Workers from "./pages/Workers";
// import Devices from "./pages/Devices";
// import DevicesManagement from "./pages/DevicesManagement";
// <RoleBasedRouting exact path="/" component={Devices} title="Dashboard" layout={MainLayout} />
// <RoleBasedRouting exact path="/devices" component={DevicesManagement}
// title="Devices" layout={MainLayout} />

const App = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login" component={Login} title={t("menu.login")} layout={LoginLayout} />
        <RoleBasedRouting exact path="/users" component={Users} title={t("menu.users")} layout={MainLayout} />
        <RoleBasedRouting exact path="/transactions" component={Transactions} title={t("menu.transactions")} layout={MainLayout} />
        <RoleBasedRouting exact path="/workers" component={Workers} title={t("menu.workers")} layout={MainLayout} />
        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
