import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

import useStyles from "./styles";

const ConfirmationPopup = ({
  isPopupOpen, handleTogglePopup, handleActionClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={isPopupOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") handleTogglePopup();
      }}
      disableEscapeKeyDown
    >
      <Box className={classes.popup}>
        <Typography variant="h6" component="h6" align="center">
          {t("users.confirmationPopup.areYouSureWantDeleteAccount")}
        </Typography>
        <Box style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
          <Button size="small" variant="contained" color="primary" onClick={handleActionClick}>
            {t("common.yes")}
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleTogglePopup} style={{ marginLeft: "10px" }}>
            {t("common.no")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleTogglePopup: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
};

export default ConfirmationPopup;
