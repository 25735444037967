const roles = {
  admin: "roles.admin",
  manager: "roles.manager",
  supervisor: "roles.supervisor",
  worker: "roles.worker",
};

const managerRoles = {
  supervisor: "roles.supervisor",
  worker: "roles.worker",
};

export {
  roles,
  managerRoles,
};
