const resources = {
  en: {
    translation: {
      common: {
        yes: "Yes",
        no: "No",
        close: "Close",
      },
      validation: {
        requiredField: "This field is required",
        lengthFrom2To30: "Field length must be between 2 and 30 characters",
        lengthShorterThan20: "Field value must be shorter than 20 characters",
        passwordMismatch: "Password mismatch",
      },
      menu: {
        login: "Login",
        users: "Users",
        workers: "Workers",
        transactions: "Transactions",
        pageNotFound: "Page not found",
      },
      form: {
        create: "Create",
        edit: "Edit",
        update: "Update",
        delete: "Delete",
        username: "Username",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        role: "Role",
        password: "Password",
        confirmPassword: "Confirm Password",
        updatePassword: "Update password",
        labelRowsPerPage: "Rows per page",
        noResults: "No results found",
        searchPlaceholder: "Search...",
        sortLabel: "Sort",
      },
      users: {
        passwordHelperText: "Password must be 2-20 characters",
        createUserAccount: "Create New User Account",
        updateUserAccount: "Update User Account",
        confirmationPopup: {
          areYouSureWantDeleteAccount: "Are you sure you want to delete user account?",
        },
      },
      transactions: {
        canNotGenerateReport: "Can not generate a report",
        user: "User",
        from: "From",
        to: "To",
        downloadExcelFile: "Download excel file",
      },
      loginForm: {
        signin: "Sign In",
      },
      roles: {
        admin: "Admin",
        manager: "Manager",
        supervisor: "Supervisor",
        worker: "Worker",
      },
      languageSwitcher: {
        en: "English",
        es: "Spanish",
      },
    },
  },
  es: {
    translation: {
      common: {
        yes: "Si",
        no: "No",
        close: "Cerrar",
      },
      validation: {
        requiredField: "Este Campo es Requerido",
        lengthFrom2To30: "El largo del campo debe de ser entre 2 y 30 caracteres",
        lengthShorterThan20: "El valor del campo debe de ser menor a 20 caracteres",
        passwordMismatch: "Las claves no son iguales",
      },
      menu: {
        login: "Login",
        users: "Usuarios",
        workers: "Empleados",
        transactions: "Transacciones",
        pageNotFound: "Pagina no Encontrada",
      },
      form: {
        create: "Crear",
        edit: "Editar",
        update: "Actualizar",
        delete: "Eliminar",
        username: "Usuario",
        firstName: "Nombre",
        lastName: "Apellido",
        email: "Email",
        role: "Funcion",
        password: "Clave",
        confirmPassword: "Confirmar Clave",
        updatePassword: "Actualizar Clave",
        labelRowsPerPage: "Lineas por pagina",
        noResults: "No se encontraron resultados",
        searchPlaceholder: "Buscar...",
        sortLabel: "Clasificar",
      },
      users: {
        passwordHelperText: "Clave debe de tener entre 2 y 20 caracteres",
        createUserAccount: "Crear Nueva Cuenta Usuario",
        updateUserAccount: "Actualizar Cuenta Usuario",
        confirmationPopup: {
          areYouSureWantDeleteAccount: "Esta seguro de querer eliminar esta cuenta de usuario?",
        },
      },
      transactions: {
        canNotGenerateReport: "No se puede generar reporte",
        user: "Usuario",
        from: "De",
        to: "A",
        downloadExcelFile: "Descargar Excel",
      },
      loginForm: {
        signin: "Ingresar",
      },
      roles: {
        admin: "Administrador",
        manager: "Gerente",
        supervisor: "Supervisor",
        worker: "Obrero",
      },
      languageSwitcher: {
        en: "Inglés",
        es: "Español",
      },
    },
  },
};

export default resources;
