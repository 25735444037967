import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { submitForm } from "./actions";

import useStyles from "./styles";
import { getSession } from "../../utils/session";
import { permissions } from "../../helpers/permissions";

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = useCallback(async () => {
    setIsLoading(true);
    const submitData = await submitForm(username, password);

    if (submitData.status) {
      const { user } = getSession() || {};
      const { role } = user;

      const path = (permissions[role] && permissions[role].default) || "/";
      history.push(path);
    } else {
      setError(submitData.message);
    }
    setIsLoading(false);
  }, [username, password, history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{t("loginForm.signin")}</Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={t("form.username")}
            name="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("form.password")}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          { error && <Typography variant="subtitle2" color="error">{error}</Typography> }
          <Button
            disabled={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmitForm}
          >
            {t("loginForm.signin")}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Login;
